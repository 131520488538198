import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./firebase/firebaseConfig";
import './assets/styles/main.scss';
import Home from './pages/Home';
import Examples from "./pages/Examples";

function App() {

  const auth = getAuth();
  const [currentUserUID, setCurrentUserUID] = useState(null);

  /* Auth listener */
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      setCurrentUserUID(uid);
    } else {
      // User is signed out
    }
  });

  return (
    <>
     <Routes>
     <Route path="/" element={<Home />} />
     <Route path="/examples" element={<Examples />} />
     </Routes>
    </>
  );
}

export default App;
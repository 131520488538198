import React, { useState, useEffect, useRef } from 'react';
import { magicShow } from "./transitions";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

function Contact() {

    const [canSend, setCanSend] = useState(true);
    const [nameValue, setNameValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [companyValue, setCompanyValue] = useState('');
    const [messageValue, setMessageValue] = useState('');
    const form = useRef();

    const emailServer = process.env.REACT_APP_EMAIl_SERVER;
    const emailTemplate = process.env.REACT_APP_EMAIL_TEMPLATE;
    const emailKey = process.env.REACT_APP_EMAIL_KEY;

    const sendEmail = (e) => {
        e.preventDefault();
        if (canSend) {
            setCanSend(false);
            emailjs.sendForm(emailServer, emailTemplate, e.target, emailKey)
                .then((result) => {
                    toast.success('The message has been successfully sent!!')
                    setNameValue('')
                    setEmailValue('')
                    setPhoneValue('')
                    setCompanyValue('')
                    setMessageValue('')
                    console.log(result.text);
                    setCanSend(true);
                    clickedButton();
                }, (error) => {
                    toast.error('The message was not sent, please try again later');
                    console.log(error.text);
                    setCanSend(true);
                });
        }
    };

    
    const clickedButton = () => {
        window.gtag('config', 'AW-11411898592');
        window.gtag('event', 'conversion', {'send_to': 'AW-11411898592/wZaECLCdr_gYEOCBz8Eq'});
    };

        /* Animation effects */
        const triggerRef = useRef();
        const headerRef = useRef();
        const textRef = useRef();
        const formRef = useRef();
        const actionRef = useRef();

        useEffect(() => {
            magicShow(triggerRef.current, [headerRef.current, textRef.current, formRef.current, actionRef.current]);
        }, []);

    return (
        <section id="contact" className='am-contact-section -lg' ref={triggerRef}>
        
            <h1 class="section-header" ref={headerRef}><h1>Contact Us</h1></h1>
            <div className="am-content">
            <div className='am-contact-container'>
                <div class="am-contact-wrapper">
                    <div class="direct-contact-container" ref={textRef}>
                        <div class="contact-details">
                            <p>Contact our factory using the contact form or our contacts listed below:</p>
                            <div class="contact-adress">
                                <a href='https://maps.app.goo.gl/PAqp5QoJUyNBLoUG7' title='Our address on the google map'>
                                    Address: 801 East 7th st. unit 402, Los-Angeles, CA90021
                                </a>
                            </div>
                        </div>
                        <div class="contact-links">
                            <span>Email: </span><a href="mailto:contact@am-wearhouse.com" title="Send us an email">contact@am-wearhouse.com</a>
                            <br />
                            <span>Tel.: </span><a href="tel:+13238914436" title="Give us a call">+1 (323) 891-4436</a>
                            <br />
                        </div>
                    </div>
                    <div className="contact-form-wrap" ref={formRef}>
                        <form ref={form} onSubmit={(e) => { sendEmail(e); }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-grp">
                                        <input id="name" type="text" name="from_name" value={nameValue} onChange={(e) => setNameValue(e.target.value)} placeholder="Name" required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-grp">
                                        <input id='email' type="email" name="from_email" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder="Email" required />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-grp">
                                        <input id="phone" type="phone" name="from_phone" value={phoneValue} onChange={(e) => setPhoneValue(e.target.value)} placeholder="Phone number" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-grp">
                                        <input id='company' type="text" name="from_company" value={companyValue} onChange={(e) => setCompanyValue(e.target.value)} placeholder="Company" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-grp">
                                <textarea id="message" name="message" value={messageValue} onChange={(e) => setMessageValue(e.target.value)} placeholder="Enter your message" required></textarea>
                            </div>
                            <div className="submit-btn text-center" ref={actionRef}>
                                <button type="submit" className="am-btn_cta">
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </section>
    );
}

export default Contact;
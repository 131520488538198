import { magicShow, magicItemShow } from "./transitions";
import { useEffect, useRef } from "react";
import photoData from "../../data/photo.json"

const Photo = () => {

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const contentRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if (el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, [headerRef.current, contentRef.current]);
    }, []);

    const renderedPhotos = photoData.map((item, index) => {
        return (
            <img src={require(`../../assets/images/photo/${item.image}.webp`)} alt={item.alt} key={index}/>
        );
    });

    return (
        <section id="photo" className="am-photo-section -lg" ref={triggerRef}>
            <div className="am-photo-container">
                <div className="section-header" ref={headerRef}><h1>Photo</h1></div>
                <div className="am-photo-wrapper" ref={contentRef}>
                    <div className="photo-scroll">
                        {renderedPhotos}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Photo;
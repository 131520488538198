import { magicShow, magicItemShow } from "./transitions";
import { useContext, useState, useEffect, useRef } from "react";
import { LayoutContext } from "../../components/context/context";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

const About = () => {

    const [isOpen, setOpen] = useState(false);
    const { scrollbar } = useContext(LayoutContext);

    const handleClick = () => {
        setOpen(current => !current);

        if (!isOpen) {
            document.body.classList.add("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        } else {
            document.body.classList.remove("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        }
    };

    const closeMenu = () => {
        setOpen(current => !current);
        document.body.classList.remove("no-scroll");
        scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
    };

        /* Animation effects */
        const triggerRef = useRef();
        const headerRef = useRef();
        const contentRef = useRef();
        const itemRef = useRef([]);
        itemRef.current = [];
    
        const combinedItemRefs = (el) => {
            if (el && !itemRef.current.includes(el)) {
                itemRef.current.push(el);
            }
        }
    
        useEffect(() => {
            magicItemShow(itemRef.current);
            magicShow(triggerRef.current, [headerRef.current, contentRef.current]);
        }, []);

    return (
        <section id="about" className="am-about-section -lg" ref={triggerRef}>
            <div className="am-about-container">
                <div className="section-header" ref={headerRef}><h1>About us</h1></div>
                <div className="am-about-content" ref={contentRef}>
                    <div className="text-block" >
                        <p>Since 2017, we have been specializing in <strong>high-quality tailoring</strong>,
                        as well as home and office textiles. Our factory is based in the
                        USA, we support domestic production and jobs. Moreover, we cooperate
                        with both business clients, brands, and individuals. By contacting our
                        company, you will receive a truly high-quality service and an optimal
                        price.</p>
                        {<br/>}
                        {<br/>}
                        <p>Our employees are specialists with more than 15 years of experience in 
                        sewing and in the manufacture of clothing in general. <strong>We make clothes 
                        of any type, for example: branded, sports, uniforms, suits, etc.</strong></p>
                    </div>
                    <div className="video-content">
                        <Link className="play-btn" onClick={handleClick}></Link>
                       {/* <img src={profileImage} alt="am-wearhouse video" /> */}
                 </div>
                </div>
                <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="i8YXWH5JNDo"
                    onClose={closeMenu}
                />
            </div>

        </section>
    );
}

export default About;
export const ButtonMenu = ({onClick}) => {
    return (
        <button className="am-btn am-btn_menu" onClick={onClick}>
            <span></span>
            <span></span>
            <span></span>
        </button>
    );
};

export default ButtonMenu;
import { magicShow, magicItemShow } from "./transitions";
import { useState, useEffect, useRef } from "react";
import CloseIcon from '@material-ui/icons/Close';
import imagesData from "../../data/gallery.json"

const Gallery = () => {

    const [model, setModel] = useState(false);

    const [tempImgPath, setTempImgPath] = useState('');

    const getImg = (path) => {
        setTempImgPath(path);
        setModel(true);
    };

    /* Animation effects */
    const triggerRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if (el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, []);
    }, []);

    const renderedPhotos = imagesData.map((item, index) => {

        return (
            <div className="pics" onClick={() => getImg(require(`../../assets/images/examples/${item.image}.webp`))} >
                <img src={require(`../../assets/images/examples/${item.image}.webp`)} style={{ width: '100%' }} alt={item.alt} ref={combinedItemRefs} key={index}/>
            </div>
        );
    });

    return (
        <>
            <div className={model ? "model open" : "model"}>
                <img src={tempImgPath} />
                <CloseIcon onClick={() => setModel(false)} />
            </div>
            <section id="gallery" className="am-gallery-section -lg" style={{ paddingTop: '50px'}} ref={triggerRef}>
                <div className="am-gallery-container">
                    <div className="section-header"><h1>Examples of our work</h1></div>
                    <div className="am-gallery-wrapper">
                        {renderedPhotos}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Gallery;
import { useContext, useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { LayoutContext } from "../context/context";
import { ButtonMenu } from '../buttons/ButtonMenu';
import logo from "../../assets/images/logo.svg"
import { Social } from "../social/Social";
import { SocialItem } from "../social/SocialItem";
import socialLinksData from "../../data/social.json"
import navLinksData from "../../data/nav-links.json"
import useScrollListener from '../hooks/useScrollListener'

const Navbar = () => {

    const location = useLocation()
    const { scrollbar } = useContext(LayoutContext);
    const [isOpen, setIsOpen] = useState(false);
    const stripRef = useRef();

    const closeMenu = () => {
        setIsOpen(current => !current);
        document.body.classList.remove("no-scroll");
        scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
    };

    const handleClick = () => {
        setIsOpen(current => !current);

        if (!isOpen) {
            document.body.classList.add("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        } else {
            document.body.classList.remove("no-scroll");
            scrollbar.current && scrollbar.current.updatePluginOptions('Disable', { disable: true });
        }
    };

    const navs = navLinksData.map((item, index) => {

        const isActive = location.hash === item.link;
        const onClickHandler = isActive ? () => setIsOpen(false) : closeMenu;

        return (
            <Link className="am-navbar-nav" key={index} to={item.link} onClick={onClickHandler}>
                <span>{item.title}</span>
            </Link>
        );
    });

    const socialItems = socialLinksData.map((item, index) => {
        return (
            <SocialItem
                name={item.name}
                link={item.link}
                icon={<img src={require(`../../assets/images/social/${item.icon}.svg`)} alt={item.name} style={{ width: '30px', margin: '0' }} />}
                key={index}
                margin={'0'}
            />
        )
    });

    /* Hide nav when scroll down */

    const [navClassList, setNavClassList] = useState([]);
    const scroll = useScrollListener();

    useEffect(() => {
        const _classList = [];

        if (scroll.y > 430 && scroll.y - scroll.lastY > 0)
            _classList.push("-hidden");

        setNavClassList(_classList);
    }, [scroll.y, scroll.lastY]);

    return (
        <nav className={`am-navbar ${isOpen ? "-open" : ""}`}>
            <div className="am-navbar-fill"></div>
            <div className={`am-navbar-strip ${navClassList.join(" ")}`} ref={stripRef}>
                <div className="am-navbar-container -lg">
                    <div className="am-navbar-grid">
                        <div className="am-navbar-grid-col -left">
                            <a className="am-navbar-logo" href="/" aria-label="Home">
                                <img src={logo} alt="" />
                            </a>
                        </div>
                        <div className="am-navbar-grid-col -center">
                            <div className="am-navbar-navs">
                                {navs}
                            </div>
                        </div>
                        <div className="am-navbar-grid-col -right">
                            <div className="am-navbar-toggle">
                                <ButtonMenu onClick={handleClick} />
                            </div>
                            <div className="am-social-nav">
                                <Social padding={'0'}>
                                    {socialItems}
                                </Social>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;

import ServicesItem from "./servicesItem/ServicesItem";
import { magicShow, magicItemShow } from "./transitions";
import { useEffect, useRef } from "react";

const Services = ({ data }) => {

    /* Animation effects */
    const triggerRef = useRef();
    const headerRef = useRef();
    const contentRef = useRef();
    const itemRef = useRef([]);
    itemRef.current = [];

    const combinedItemRefs = (el) => {
        if (el && !itemRef.current.includes(el)) {
            itemRef.current.push(el);
        }
    }

    useEffect(() => {
        magicItemShow(itemRef.current);
        magicShow(triggerRef.current, [headerRef.current, contentRef.current]);
    }, []);

    /* Data rendering */
    const renderedServices = data.map((item, index) => {

        return (
            <ServicesItem
                item={item}
                index={index}
                ref={combinedItemRefs}
            />
        );
    });

    return (
        <section id="services" className="services-section" ref={triggerRef}>
            <div className="am-services-container">
                <div className="section-header" ref={headerRef}><h1>Services</h1></div>
                <div className="am-services-content">
                    <ul className="services-list">
                        {renderedServices}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Services;
export const SocialItem = ({name, link, icon , margin}) => {
    return (
        <a
            className="am-social-item"
            href={link}
            aria-label={name}
            target="_blank"
            rel="noreferrer"
            style={{margin: margin}}
        >
            {icon}
        </a>
    );
};
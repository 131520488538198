import logo from "../../assets/images/logo.svg"
import { Social } from "../social/Social";
import { SocialItem } from "../social/SocialItem";
import { Link } from "react-router-dom";
import socialLinksData from "../../data/social.json"
import navLinksData from "../../data/nav-links.json"
import privacyLinksData from "../../data/privacy-links.json"

const Footer = () => {

    const footerData = [
        {
            title: 'Navigation',
            navs: navLinksData
        },
        {
            title: 'Security',
            navs: privacyLinksData
        },
    ];

    const cols = footerData.map((col, index) => (
        <div className="am-footer-ngrid-col" key={index}>
            <div className="am-footer-title">{col.title}</div>
            <div className="am-footer-navs">
                {
                    col.navs.map((nav, index) => (
                        <div className="am-footer-nav" key={index}>
                            <Link to={nav.link}>
                                <span>{nav.title}</span>
                            </Link>

                        </div>
                    ))
                }
            </div>
        </div>
    ));

    const socialItems = socialLinksData.map((item, index) => {
        return (
            <SocialItem
                name={item.name}
                link={item.link}
                icon={<img src={require(`../../assets/images/social/${item.icon}.svg`)} alt={item.name} />}
                key={index}
            />
        )
    });

    return (
        <div className="am-footer white-bg">
            <div className="am-footer-content">
                <div className="am-footer-container">
                    <div className="am-footer-row">
                        <div className="am-footer-grid">
                            <a className="am-footer-logo" href="/">
                                <img src={logo} alt="Logo" />
                            </a>
                            <div className="am-footer-social">
                                <Social>
                                    {socialItems}
                                </Social>
                            </div>
                            <div className="am-footer-ngrid">
                                {cols}
                            </div>
                        </div>
                    </div>
                    <div className="am-footer-row">
                        <div className="am-footer-copyright"><span>Copyright © 2023, AM Wearhouse. All rights reserved.</span></div>
                        <div className="am-footer-developed"><span>Developed by <a href="https://nelcosoft.com">Nelcosoft</a></span></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;

import "./style/promotion.scss"
const PromotionLine = () => {

    return (
        <div class="marquee">
            <div class="marquee__inner">
                <p class="marquee__line">Package sale! *** For only 2000$ *** You will get: Patterns Development * Sample * 30 pieces production * Photoshoot location * Photographer</p>
                <p class="marquee__line">Package sale! *** For only 2000$ *** You will get: Patterns Development * Sample * 30 pieces production * Photoshoot location * Photographer</p>
            </div>
        </div>
    );
};

export default PromotionLine;
import Gallery from "../sections/gallery/Gallery";
import Layout from "../components/layout/Layout";

function Examples() {

  return (
    <Layout>
      <Gallery />
    </Layout>
  );
}

export default Examples;
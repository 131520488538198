const Banner = () => {

    return (
        <header className="am-hero">
            <div className="am-hero-content -cb">
                <div className="am-hero-container">
                    <div className="am-hero-main">
                        <div className="am-hero-video" alt="process of sewing on single stitch machine">
                            {/* <img src={heroimage} alt="" /> */}
                        </div>
                        <div className="am-hero-body">
                            <div className="am-hero-left-content">
                                <div className="am-hero-header">
                                    <h1>AM WEARHOUSE</h1>
                                </div>
                                <div className="am-hero-text">
                                    <strong>High quality tailoring made in USA</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}

export default Banner;
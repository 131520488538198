import React, { forwardRef } from 'react';

const ServicesItem = forwardRef((props, ref) => (
  <li ref={ref} key={props.index}>
    <div className="service-item-icon" >
      <img src={require(`../../../assets/images/services-icons/${props.item.icon}.svg`)} alt={props.item.text} />
    </div>
    <div className="service-item-text">
      <p>{props.item.text}</p>
    </div>
  </li>
));

export default ServicesItem;
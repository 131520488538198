import About from "../sections/about/About";
import Banner from "../components/banner/Banner";
import Contact from "../sections/contact/Contact";
import Layout from "../components/layout/Layout";
import Photo from "../sections/photo/Photo";
import Services from "../sections/services/Services";
import servicesData from "../data/services.json"
import PromotionLine from "../sections/promotion/promotion";

function Home() {

  return (
    <Layout>
      <Banner />
      <PromotionLine/>
      <About />
      <Services data={servicesData}/>
      <Photo/>
      <Contact />
    </Layout>
  );
}

export default Home;